import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { environment as env } from '../../environments/environment';   
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, catchError, map,  switchMap, throwError } from 'rxjs';
import { ToastService } from '../features/components/toast/toast.service'; 
import { HttpClient  } from '@angular/common/http'; 
import { LanguagesService } from './languages/languages.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // headers = new HttpHeaders({ 
  //   'Audience': env.oAauthConfig.audience,
  //   'Content-Type': 'application/json',
  //   'X-Timezone':   sessionStorage.getItem('country') || 'UTC'
  // });

  isAuthenticated = new BehaviorSubject<boolean>(false); 
  loggedUserId = '';
  loggedUser: any = null;
  loggedUser$ = new BehaviorSubject<any>(null); 
  userRole$ = new BehaviorSubject<string>('???'); 

  activeToast: any = null;
   

  constructor(  
      public oAuth: AuthService, 
      @Inject(DOCUMENT) public document: Document,  
      private langService: LanguagesService,
      private http: HttpClient,  
      private toastService: ToastService ) { }


  //? ** to get ALL LOGGED user details  (oAuth)
  getLoggedOAuthUser(): Observable<any | null> {
    return this.oAuth.user$.pipe(
      map(( user: any ) => {
        if (user && user.sub) {
          this.loggedUserId = user.sub; 
          return user;
        } else {
          return null;
        }
      }),
      catchError(error => { 
        return throwError(() => error);
      })
    );
  }

  //? ** to enrich ALL LOGGED user details with more BE data (metadata)
  getLoggedUser(): Observable<any> { 
    return this.getLoggedOAuthUser().pipe(
      switchMap((user: any | null) => {
        if (user === null) { 
          throw { error: { message: 'NO user logged profile' } };
        } else {
          // return this.getUserSettingsByUserId(user.sub).pipe(   // WAS
          return this.getUserSettingsByUserId().pipe(   
            map( userMetadata => { 
              this.loggedUser$.next({ ...this.loggedUser$.value , metadata: userMetadata });     
       
              return { ...user , metadata: userMetadata, loggedUserId: user.sub };
            })
          );  
        }
      }),
      catchError(error => {              
        error.error.message += ' ::: [error in getLoggedUser]'; 
        return throwError(() => error);
      })
    );
  } 

  getUserSettings(): Observable< any | null>  {   
    const url = `${env.baseServerURL}api/users/settings`;  

    return this.http.get<any>(url, {
      // headers: this.headers,
      observe: 'response'
    }).pipe(
      map(response => {        
        if ( response.body && !response.body.success   ) {    
          throw { error: { message: response.body.message } }
        } else {      

          //?  FIREBASE TRASNLATION  **********************  
          const  missingSettings = response.body.data.missingSettings.map( (item: any) => { 
            item.disclaimer = this.langService.translations$.value[item.disclaimer] ?? item.disclaimer   ;  
            item.label = this.langService.translations$.value[item.label] ?? item.label   ; 
            return item;
          });

          const settings = response.body.data.settings.map( (item: any) => { 
            item.disclaimer = this.langService.translations$.value[item.disclaimer] ?? item.disclaimer   ;  
            item.label = this.langService.translations$.value[item.label] ?? item.label   ; 
            return item;
          });

          if(this.userRole$.value === '???') {
            this.userRole$.next( response.body?.data?.role );
          } 

        const translatedRes = { missingSettings, settings };   

          this.loggedUser$.next({ ...this.loggedUser$ , metadata: translatedRes   }); 

          return { ...this.loggedUser$ , metadata: translatedRes }; 
        }     
      }) 
    ); 
}
 

  getUserSettingsByUserId(): Observable< any | null>  {     
        const url = `${env.baseServerURL}api/users/settings`;  

        return this.http.get<any>(url, { 
          observe: 'response'
        }).pipe(
          map(response => {        
            if ( response.body && !response.body.success   ) {  
              throw { error: { message: response.body.message } }
            } else {      

              //?  FIREBASE TRASNLATION  **********************  
              const  missingSettings = response.body.data.missingSettings.map( (item: any) => { 
                item.disclaimer = this.langService.translations$.value[item.disclaimer] ?? item.disclaimer   ;  
                item.label = this.langService.translations$.value[item.label] ?? item.label   ; 
                return item;
              });

              const settings = response.body.data.settings.map( (item: any) => { 
                item.disclaimer = this.langService.translations$.value[item.disclaimer] ?? item.disclaimer   ;  
                item.label = this.langService.translations$.value[item.label] ?? item.label   ; 
                return item;
              });

              if(this.userRole$.value === '???') {
                this.userRole$.next( response.body?.data?.role );
              } 

              const translatedRes = { missingSettings, settings };    

              return  translatedRes ; 
            }     
          }) 
        ); 
  }

 

  setUserSettings(payload: any): Observable< any | null>  {  
      const url = `${env.baseServerURL}api/users/settings`;  

      return this.http.put<any>(url, payload, {
        // headers: this.headers,
        observe: 'response'
      }).pipe(
        map(response => {        
          if ( response.body && !response.body.success   ) { 
            throw { error: { message: response.body.message } }
          } else {   
            return  response.body ; 
          }     
        }) 
      ); 
  }
 

  login() {
    this.oAuth.loginWithRedirect();
  }

  logout() {
    this.oAuth.logout({
      logoutParams: {
        returnTo: this.document.location.origin
      }
    });
  }
}
