
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../interfaces/app-state';    

// Selettore per ottenere tutto lo stato dello APP Store  

export const getAppState =  createFeatureSelector<AppState>('appstate');
export const getPlatform = createSelector( getAppState,  (state: AppState) => state.appstate?.platform  ); 
export const getLoggedUser = createSelector( getAppState,  (state: AppState) => state.appstate.loggedUser );

 

//? createSelector è una funzione che prende un "array di selettori" e una funzione che ritorna il nuovo stato di uno specifico selettore.
 