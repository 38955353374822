import { Injectable } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree  } from '@angular/router';
import { Observable, of } from 'rxjs'; 
import { map, filter, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ToastService } from '../features/components/toast/toast.service';
import { LanguagesService } from '../services/languages/languages.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(
    private authService: AuthenticationService,  
    private langService: LanguagesService,
    private toastService: ToastService) {}

  canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

      const expectedRoles: string[] = route.data['expectedRoles'];   

      return this.authService.userRole$.pipe(
        filter(role => role !== '???'),  //! deve corrispondere con il valore di default in AuthenticationService
        map((role: string) => expectedRoles.includes(role)), // Restituisce true se il ruolo è incluso
        switchMap((canActivateRoute: boolean) => { 
          if (!canActivateRoute) {
            const message = this.langService?.translations$?.value?.['forbidden-route'] ?? 'forbidden-route';
            const toast = this.toastService.showToast(  message, 'error', 'error');
            setTimeout(() => this.toastService.removeToast(toast.id, false), 5000); 
          } 
          return of(canActivateRoute);
        })
      );
      
  }
}
