import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators'; 
import * as AppActions from './app.actions'; 
import { of } from 'rxjs'; 
import { AuthenticationService } from '../services/authentication.service';
import { OauthLoggedUserInterface } from '../interfaces/oauth-logged-user'; 

@Injectable()
export class AppEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService
  ) {}
    
  loadLoggedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.loadLoggedUser),  //? ascolta solo questa action ( ma potrebbe ascoltarne contemporaneamente altre, se avesse senso )
      mergeMap(() => this.authService.getLoggedUser() 
        .pipe(
          map((res: OauthLoggedUserInterface)  => AppActions.loadLoggedUserSuccess({ loggedUser: res })),
          catchError( (error) => of(AppActions.loadLoggedUserFailure( {error}))  
        )
      )
    )
  ));  


}
