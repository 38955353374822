import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';   
import { CustomDateFormatPipe } from 'src/app/pipes/custom-date-format.pipe';

@Component({
  selector: 'app-app-version',
  standalone: true,
  imports: [CommonModule, CustomDateFormatPipe],
  template: `<i>Version:</i>&nbsp;&nbsp;<b>{{ appVersion }}</b>
             <br>
             <i>Updated:</i>&nbsp;&nbsp;<b>{{ lastUpdate | date:'dd-MM-yyyy HH:mm:ss' }}</b>
             <br>
             <i>Environment:</i>&nbsp;&nbsp;<b>{{ environment | uppercase }}</b>

             <!-- button (click)="buildAndDeploy()" class="mt-4 text-gray-700 cursor-pointer">RUN BUILD & DEPLOY</button -->
            ` ,
  styles:  [`  `]
})
export class AppVersionComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();  
  appVersion = '';
  lastUpdate = '';
  environment: any = env.environment ;

  constructor(private http: HttpClient) {  }

  ngOnInit(): void {
    this.getVersionInfo(); 
  }

  getVersionInfo()  { 

        const url = `/assets/json/version-info.json`;   

        this.http.get<any>(url).pipe(
          takeUntil(this.unsubscribe$),
        ).subscribe({ 
          next: (data) => {     
            this.appVersion = data.version;
            this.lastUpdate = data.buildDate; 
            this.environment = data.environment;
          } 
        });
  }

  buildAndDeploy() {

    const url = `http://localhost:3100/trigger-action`;   

    this.http.post<any>(url, {
      pippo: 'Ciao'
    }).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
 
}
