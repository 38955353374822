import { Injectable } from '@angular/core';
import { environment as env } from './../../environments/environment';   
import * as ExcelJS from 'exceljs';
import fs from 'file-saver';
import * as Papa from 'papaparse';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas'; 
import { Observable, map } from 'rxjs';
import { ToastService } from '../features/components/toast/toast.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  // headers = new HttpHeaders({ 
  //   'Audience': env.oAauthConfig.audience,
  //   'Content-Type': 'application/json',
  //   'X-Timezone':   sessionStorage.getItem('country') || 'UTC'
  // });

  constructor(private http: HttpClient,  private toastService : ToastService ) { }

  getContactLinks(): Observable<any>  { 
      const url = `${env.baseServerURL}public/generals/contactLinks`;   
      
      return this.http.get<any>(url, {
        // headers: this.headers,
        observe: 'response'
      }).pipe(
        map(response => {        
          if ( response.body && !response.body.success   ) { 
            throw { error: { message: response.body.message } }
          } else {    
            return  response.body.data;     
          }     
        }) 
      );

  }
 

  // WAS BEFORE LINT  
  // flattenObject(ob: any) {
  //   const toReturn: { [key: string]: any } = {};
  //   for (const i in ob) {
  //     if (!ob.hasOwnProperty(i)) continue;
  //     if ((typeof ob[i]) == 'object' && ob[i] !== null) {
  //       const flatObject = this.flattenObject(ob[i]);
  //       for (const x in flatObject) {
  //         if (!flatObject.hasOwnProperty(x)) continue;
  //         toReturn[i + '-' + x] = flatObject[x];
  //       }
  //     } else {
  //       toReturn[i] = ob[i];
  //     }
  //   }
  //   return toReturn;
  // }

  flattenObject(ob: any) {
    const toReturn: { [key: string]: any } = {};
    for (const i in ob) {
      // Modifica qui
      if (!Object.prototype.hasOwnProperty.call(ob, i)) continue;
  
      if (typeof ob[i] == 'object' && ob[i] !== null) {
        const flatObject = this.flattenObject(ob[i]);
        for (const x in flatObject) {
          // E anche qui
          if (!Object.prototype.hasOwnProperty.call(flatObject, x)) continue;
          
          toReturn[i + '-' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }
  



  exportDataPDF(title: string, domId: string) {
    // Select the area you want to generate a PDF of
    const table = document.getElementById(domId);

    const isDisplayed = table && window.getComputedStyle(table).display !== 'none';
    const isVisible = table && window.getComputedStyle(table).visibility !== 'hidden'; 
    
    if (table === null)  return ; 
    if (!isDisplayed)  return ; 
    if (!isVisible)  return ; 

    html2canvas(table).then(canvas => {
      // Generate the PDF
      const imgWidth = 208; 
      // const pageHeight = 295;  
      const imgHeight = canvas.height * imgWidth / canvas.width;
      // const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      const pdf = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

      const date = new Date().getTime();
      pdf.save(`${title} - ${date}.pdf`);
    });
  }
  

  
  exportDataExcel(title: string, jsonData: any[]) {
 
    // Create a workbook with a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    // Add headers to worksheet
    // let headers = Object.keys(jsonData[0]);
    const flatRowHeader =  Object.keys(this.flattenObject(jsonData[0])); 

    flatRowHeader.forEach((key, index) => {
      // ExcelJS columns start from 1, not 0, so add 1 to the index
      const excelColumnNumber = index + 1;

      // Set the value in the first row (the header) of the current column
      worksheet.getRow(1).getCell(excelColumnNumber).value = key;
    });

    // Add JSON data to worksheet
    jsonData.forEach((row, index) => { 

      const flatRow = this.flattenObject(row);   
      // ExcelJS rows also start from 1, not 0, and we have already headers at row 1
      // so we add 2 to the index
      const excelRowNumber = index + 2;

      Object.keys(flatRow).forEach((key, index) => { 

        // ExcelJS columns start from 1, not 0, so add 1 to the index
        const excelColumnNumber = index + 1; 
         // Add the actual data in the correct row
        worksheet.getRow(excelRowNumber).getCell(excelColumnNumber).value = flatRow[key];
      }); 
     
    });

    // Write the workbook to a blob
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const date = new Date().getTime();
      
      // Use FileSaver to save the file
      fs.saveAs(blob,  `${title} - ${date}.xlsx`);
    });
  }


 

  exportDataCSV(title: string, jsonData: any[]) {
    const flatArray = jsonData.map((row) => this.flattenObject(row));
 
    // Convert JSON to CSV data
    const csvData = Papa.unparse(flatArray);

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    
    const link = document.createElement('a');
    if (link.download !== undefined) { 
      const date = new Date().getTime();  

      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${title} - ${date}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
 
  }


  downloadDataZip(pathApi: string , fileTitle: string ): Observable< any | [] > { 
  
    const headers = new HttpHeaders({ 
      'Content-Type': 'application/zip' //! ATTENZIONE che questa call ha uno specifico content type
    });

    const url = `${env.baseServerURL}${pathApi}`;    

    return this.http.get<any>(url, {
      headers: headers,  //! ATTENZIONE che questa call ha uno specifico content type
      observe: 'response', 
      responseType: 'blob' as 'json' 
    }).pipe(
      map(response => {                 
            const blob = new Blob([response.body], { type: 'application/zip' }); 
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileTitle}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
      }) 
    );
  }

  

}
