<!-- SIDE BAR MENU -->
<app-side-bar-menu ></app-side-bar-menu>
 
<!-- CONTENT --> 
<div class="px-4 py-2 xl:ml-64 h-full">  

    <!-- ERRORS TOAST  -->
    <app-toast></app-toast>

    <!-- RENDERING COMPONENTS -->
    <router-outlet></router-outlet>   
 
</div>
 




