import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { ReactiveFormsModule } from '@angular/forms';
import { DetectEventsPipe } from '../pipes/detect-event.pipe'; 
import { InitialsPipe } from '../pipes/initials.pipe'; 
import { ValueSplitPipe } from '../pipes/valuesplit.pipe';
import { StateiconPipe } from '../pipes/stateicon.pipe';
import { DurationPipe } from '../pipes/duration.pipe'; 
import { TooltipDirective } from '../directives/tooltip.directive';
import { HasRoleDirective } from '../directives/has-role.directive'; 
import { TooltipDirectiveFlowbiteWrapperDirective } from '../directives/tooltip-flowbite-wrapper.directive';
import { KeyValuePipe } from '../pipes/keyvalue.pipe';

@NgModule({
  declarations: [ 
    DetectEventsPipe, 
    InitialsPipe,
    TooltipDirective,
    TooltipDirectiveFlowbiteWrapperDirective, 
    ValueSplitPipe,
    StateiconPipe,
    DurationPipe,
    KeyValuePipe,
    HasRoleDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule 
  ],
  exports: [   
    InitialsPipe, 
    DetectEventsPipe,
    ReactiveFormsModule,
    TooltipDirective,
    TooltipDirectiveFlowbiteWrapperDirective, 
    ValueSplitPipe,
    StateiconPipe,
    DurationPipe,
    KeyValuePipe,
    HasRoleDirective
  ]
})
export class SharedModule { }
