  <!-- HAMBURGER BUTTON TO OPEN SIDEBAR -->
  <button 
        type="button" 
        data-drawer-target="sidebar-menu" 
        data-drawer-show="sidebar-menu" 
        aria-controls="sidebar-menu" 
        class="btn-sandwich">
    <span class="sr-only">Open sidebar</span>
    <svg class="w-5 h-5"   fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
       <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
    </svg>
  </button>
  
  <!-- SIDEBAR MENU  -->
  <aside id="sidebar-menu"  #sidebarMenu
    class="h-screen  bg-gray-300 dark:bg-black transition-transform -translate-x-full xl:translate-x-0 fixed top-0 left-0 z-40 w-64 " aria-label="Sidebar">
    
    <!-- TOGGLE TO CLOSE SIDEBAR   -->
    <button type="button" 
      data-drawer-hide="sidebar-menu" 
      aria-controls="sidebar-menu" 
      class="btn-toggle-sidebar  absolute top-1 right-3  z-40" >
      <svg   class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      <span class="sr-only">Close menu</span>
    </button>
                                                        
    <div class="h-full px-3 py-4 pb-40 overflow-y-auto "  >
       <div class="flex h-16 shrink-0 items-center"> 
          <img  class="  h-14 w-14 rounded-lg  "  [src]="(appState$ | async)?.platform?.logo" [title]="(appState$ | async)?.platform?.name" [alt]="(appState$ | async)?.platform?.name + ' logo'"> 

          <span *ngIf="!(oAuth.isLoading$ | async ) && ENV !== 'PRODUCTION'" 
            class="mt-4 text-yellow-500 border-yellow-500  ml-2 font-semibold  text-md border-1 px-4 rounded-full ">{{ ENV }}</span> 
           
       </div> 

      <!-- FOR DEVELOPMENT ONLY :) -->   
      <div *ngIf="!(oAuth.isLoading$ | async ) && domain === 'localhost'"  class="block"   (click)="closeSidebar()"> 
        <span class="ml-4 text-orange-400 dark:text-yellow-400 " >{{ auth.userRole$ | async  }}</span> 
        <span class="ml-4 text-yellow-400 text-lg  sm:hidden md:hidden lg:hidden">XS</span>
        <span class="ml-4 text-red-300 text-lg hidden  sm:block md:hidden lg:hidden">SM</span>
        <span class="ml-4 text-purple-400 text-lg hidden  md:block lg:hidden">MD</span>
        <span class="ml-4 text-green-400 text-lg hidden  lg:block xl:hidden">LG</span>
        <span class="ml-4 text-blue-400 text-lg hidden  xl:block ">XL</span>
      </div>

      <div class="mt-1  h-auto "> 
        <ul *ngIf="!(oAuth.isLoading$ | async) && (langService.translations$ | async  )"   class=" font-medium mt-1 relative flex flex-col justify-between gap-y-2 ">   
          
            <!-- HOME  -->
            <li class="">
              <a (click)="closeOpensections()" class="section flex items-center justify-between"
                routerLink="/home" 
                routerLinkActive="cursor-pointer bg-gray-200 dark:bg-gray-700"  >
                <span  class="material-symbols-outlined sectionIcon "> Home </span>
                <span class="sectionLabel" >{{ (langService.translations$ | async  )?.['sidebarmenu-section-home'] ?? 'sidebarmenu-section-home' }}</span>  
                <app-spinner *ngIf="(oAuth.isAuthenticated$ | async ) && (auth.userRole$ | async ) === '???'" [color]="'grey'" [outClass]="'h-6'"></app-spinner> 
              </a> 
            </li> 

            <!-- DYNAMIC STRUCTURE TEST  -->           
            <div  *ngIf="oAuth.isAuthenticated$ | async "   class=" h-auto space-y-2"> 
              <div *ngFor="let section of menuSections" [title]="section.menuSectionDescription" class="flex flex-col gap-y-2 " >
                <li  *appHasRole="section.roles">
                    <a  (click)="expandMenuSection(section.menuSection)"  class="section"
                        [ngClass]="{'bg-gray-200 dark:bg-gray-700' : openSections[section.menuSection] }" >
                        <span class="material-symbols-outlined sectionIcon ">{{ section.icon }}</span>
                        <span class="sectionLabel ">{{  (langService.translations$ | async  )?.[section.sectionTitle]  ?? section.sectionTitle }} </span>
                        <svg *ngIf="section.subSections"  [ngClass]="[openSections[section.menuSection] ? 'rotate-180' : '', 'h-5 w-5 flex-none']"    
                          class="h-5 w-5 flex-none rotate-" viewBox="0 0 20 20" fill="currentColor"  >
                          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                    </a> 
                    <ng-container *ngIf="section.subSections"  >
                      <ul *ngIf="openSections[section.menuSection]" class="mt-2 ml-0 space-y-2"  >   
                          <li  *ngFor="let subSection of section.subSections">
                            <ng-container *appHasRole="subSection.roles"   >
                                <a *ngIf="subSection.routerLink"  class="subsection"
                                  [routerLink]="subSection.routerLink"   
                                  routerLinkActive="linkactive">
                                  <span class="material-symbols-outlined subsectionIcon">{{ subSection.icon }} </span>
                                  <span class="subsectionLabel" > {{ (langService.translations$ | async  )?.[subSection.subSectionTitle]  ?? subSection.subSectionTitle }} </span>
                                </a> 

                                <a *ngIf="subSection.href"   class="subsection"
                                  [href]="subSection.href" 
                                  [class.linkactive]="isLinkActive(subSection.linkActive )" >
                                  <span class="material-symbols-outlined subsectionIcon">{{ subSection.icon }} </span>
                                  <span class="subsectionLabel" > {{ (langService.translations$ | async  )?.[subSection.subSectionTitle]  ?? subSection.subSectionTitle }} </span>
                                </a> 
                            </ng-container>                           
                          </li>  
                      </ul> 
                    </ng-container>  
                </li> 
              </div> 
            </div>  
            
            <!-- CONTENUTI GENERICI -->
            <div class=" h-auto  space-y-2 mt-10">

                  <!-- LOGIN / LOGOUT  -->          
                  <li *ngIf="!(oAuth.isAuthenticated$ | async)">
                    <a (click)="login()" class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700">
                      <span class="material-symbols-outlined  flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                        login 
                      </span>
                      <span class="flex-1 ml-3 whitespace-nowrap first-letter:capitalize">{{ (langService.translations$ | async  )?.['sidebarmenu-section-login']  ?? 'sidebarmenu-section-login' }}</span>
                    </a>
                  </li>
                  <li *ngIf="oAuth.isAuthenticated$ | async " >
                    <a (click)="logout()" class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700">
                      <span class="material-symbols-outlined  flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                        logout
                      </span>
                      <img *ngIf="(pic$ | async )" [src]="(pic$ | async )" class="flex-shrink-0 w-6 h-6 rounded-full ml-2" [title]="(pic$ | async )"  alt="(pic$ | async )" />
                      <span class="flex-1 ml-3 whitespace-nowrap first-letter:capitalize">{{ (langService.translations$ | async  )?.['sidebarmenu-section-logout']  ?? 'sidebarmenu-section-logout' }}</span>
                    </a>
                  </li> 

                  <!-- ASSISTENZA  -->
                  <li *ngIf="oAuth.isAuthenticated$ | async " class=" w-full  ">
                    <a (click)="closeOpensections()"  [routerLink]="['assistance']"  routerLinkActive="cursor-pointer bg-gray-200 dark:bg-gray-700" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700">
                      <span class="material-symbols-outlined  flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                      support_agent
                      </span> 
                      <span class="ml-3 first-letter:capitalize">{{ (langService.translations$ | async  )?.['sidebarmenu-section-assistance']  ?? 'sidebarmenu-section-assistance' }}</span>
                    </a>
                  </li> 

                  <!-- KIT DOCUMENTALE  --> 
                  <li *ngIf="oAuth.isAuthenticated$ | async">
                    <a  (click)="expandMenuSection('kitdoc')"  class="section"
                        [ngClass]="{'bg-gray-200 dark:bg-gray-700' : openSections['kitdoc'] }" >
                        <span class="material-symbols-outlined sectionIcon ">  picture_as_pdf  </span>
                        <span class="sectionLabel ">{{  (langService.translations$ | async  )?.['sidebarmenu-section-docs-kit']  ?? 'sidebarmenu-section-docs-kit' }} </span>
                        <svg [ngClass]="[openSections['kitdoc'] ? 'rotate-180' : '', 'h-5 w-5 flex-none']"    
                          class="h-5 w-5 flex-none rotate-" viewBox="0 0 20 20" fill="currentColor"  >
                          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                    </a>                  
                      <ul *ngIf="openSections['kitdoc']" class="mt-2 ml-0 space-y-2"  >   
                          <li> 
                                <a  class="subsection"
                                  (click)="openModalKit('kit-lite')"   
                                  [ngClass]="(doctype === 'lite') ? 'bg-gray-200 dark:bg-gray-700' : ''" >
                                  <span class="material-symbols-outlined subsectionIcon">  picture_as_pdf </span>
                                  <span class="subsectionLabel" > {{ (langService.translations$ | async  )?.['sidebarmenu-section-docs-kit-lite']  ?? 'sidebarmenu-section-docs-kit-lite' }} </span>
                                </a>       
                          </li>  
                          <li> 
                                <a  class="subsection"
                                  (click)="openModalKit('kit-full')"   
                                  [ngClass]="(doctype === 'full') ? 'bg-gray-200 dark:bg-gray-700' : ''" >
                                  <span class="material-symbols-outlined subsectionIcon">  picture_as_pdf </span>
                                  <span class="subsectionLabel" > {{ (langService.translations$ | async  )?.['sidebarmenu-section-docs-kit-full']  ?? 'sidebarmenu-section-docs-kit-full' }} </span>
                                </a>       
                          </li>   
                          <li> 
                                <a  class="subsection"
                                  (click)="openModalKit('manuals')" 
                                  [ngClass]="(doctype === 'manuals') ? 'bg-gray-200 dark:bg-gray-700' : ''" >
                                  <span class="material-symbols-outlined subsectionIcon">  picture_as_pdf </span>
                                  <span class="subsectionLabel" > {{ (langService.translations$ | async  )?.['sidebarmenu-section-docs-kit-manuals']  ?? 'sidebarmenu-section-docs-kit-manuals' }} </span>
                                </a>       
                          </li>  
                      </ul>  
                </li> 

                  <!-- MANAGE ACCOUNTS  -->
                  <li *ngIf="oAuth.isAuthenticated$ | async "   class=" w-full  "> 
                    <a  (click)="openSettingsModal('manage-account-modal')" [ngClass]="{'bg-gray-200 dark:bg-gray-700' : isModalOpen}" class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700">
                      <span class="material-symbols-outlined  flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                            manage_accounts 
                      </span>
                      <span class="flex-1 ml-3 whitespace-nowrap first-letter:capitalize" >{{ (langService.translations$ | async  )?.['sidebarmenu-manage-account']  ?? 'sidebarmenu-manage-account' }} </span>
                    </a>
                  </li>

                  <!-- THEME  -->
                  <li class=" w-full "> 
                    <a  (click)="switchTheme()" [ngClass]="{'bg-gray-200 dark:bg-gray-700' : false}" class="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700">
                      <span class="material-symbols-outlined  flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                        {{ themeIcon }}
                      </span>
                      <span class="flex-1 ml-3 whitespace-nowrap first-letter:capitalize" >
                        {{ (langService.translations$ | async  )?.[theme]  ?? theme }} 
                      </span>
                    </a>
                  </li>

                  <!-- LANGUAGE  -->
                  <li>   
                    <app-combo-custom #langsInput style="min-width: 160px" 
                      [label]="''" 
                      [data]="langService.languages" 
                      [value]="cookieLang"   
                      (setFieldValue)="setSelectedLanguage($event)" ></app-combo-custom>  
                  </li>


                  <!-- CONTACTS  -->
                  <li class="w-full ">   
                    <div class="h-auto w-full">
                      <div class="flex items-center  border-1 border-gray-900 rounded-md bg-gray-200 dark:bg-transparent p-2">
                        <div class="h-12 w-12 ">
                          <img [src]="(appState$ | async)?.platform?.logo" class="p-1 h-12  w-12 rounded-full  dark:bg-white" [alt]="(appState$ | async)?.platform?.name + ' logo'" />
                        </div>
                        <div class="ml-4 flex-shrink-0">

                            <div class="flex  items-center font-medium text-gray-900  dark:text-white first-letter:capitalize">
                                <span class="ml-1 font-semibold"> 
                                  {{ (appState$ | async)?.platform?.name  }} 
                                </span>
                            </div>

                            <div class="flex  items-center font-medium text-gray-900  dark:text-white first-letter:capitalize">
                              <span class="material-symbols-outlined  flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                                link  
                              </span>
                              <a [href]="(appState$ | async)?.platform?.website " target="_blank" class="ml-1 font-semibold">Sito Web</a>
                            </div>

                            <div class="flex  items-center font-medium text-gray-900  dark:text-white first-letter:capitalize">
                              <span class="material-symbols-outlined  flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                                link  
                              </span>
                              <a [href]="(appState$ | async)?.platform?.companyStore" target="_blank" class="ml-1 font-semibold">Store</a>
                            </div> 
                        </div>
                      </div>
                    </div> 
                  </li> 
                  
            </div>  

            <!-- FOOTER  -->
            <div class=" h-auto  space-y-2 mt-10">
              <li class=" w-full  text-xs text-gray-500 dark:text-gray-600 ">
                <app-app-version></app-app-version>
              </li>
            </div>
        </ul>
      </div>

    </div>

  </aside> 
  
 
  <!-- MANAGE ACCOUNT modal -->
  <div id="manage-account-modal" tabindex="-1"   class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-md max-h-full">

        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            
            <div class="px-6 py-6 lg:px-8     modal-settings" >
              <div class="flex items-center justify-between px-2">
                <h3 class="uppercase">{{  (langService.translations$ | async  )?.['modal-manage-account-title'] ?? 'modal-manage-account-title' }}</h3>
            
                
                <button type="button" 
                    (click)="submitData('manage-account-modal')"   
                    class=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"  >
                    <svg   class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
              </div>
                
              <div class="body py-4">   
              <fieldset class="">  
                <legend class="px-2 text-md font-medium  text-gray-700 uppercase">{{  (langService.translations$ | async  )?.['modal-legend-settings'] ?? 'modal-legend-settings' }}</legend>   

                <div *ngIf="showError" class="err">{{  error }}</div>
        
                <div  *ngFor="let item of  (modalUserSettingsData$ | async )  " class="box" [ngClass]="{'mandatory': item?.mandatory }">  
                  <div class="disclaimer">{{ item?.disclaimer}}</div>
                  
                  <label *ngIf="item?.type === 'checkbox'"  class=" relative cursor-pointer  flex items-center " >
                    <input 
                        type="checkbox"  
                        class="sr-only peer"  
                        [(ngModel)]="formValues[item?.name]" 
                        [ngClass]="{'disabled': item?.readonly}"  
                        [checked]="item?.value" 
                        [name]="item?.name" 
                        [readonly]="item?.readonly"    >

                    <div class="w-14 h-8 after:top-[4px] after:left-[5px] after:h-6 after:w-6 
                        peer-checked:after:translate-x-full  after:content-[''] after:border after:rounded-full after:transition-all  rounded-full peer peer-focus:ring-4 after:absolute 
                        border-[1px] border-black bg-gray-300 after:bg-gray-400 after:border-gray-600   peer-checked:after:border-white peer-checked:after:bg-white
                        toggleGreen"></div>  
                    <div class="label">{{ item?.label  }}</div>  
                  </label> 

                  <div *ngIf="item?.type === 'text'"    class=" relative cursor-pointer   flex items-center"  >  
                      <input  
                          [(ngModel)]="formValues[item?.name]" 
                          [ngClass]="{'disabled': item?.readonly}"  
                          [readonly]="item?.readonly" 
                          [value]="item?.value"   
                          [name]="item?.name" 
                          type="text" 
                          [required]="item?.mandatory"  
                          class="actInputFld" 
                          style="background-color: white; color: black"  > 

                          <label class="label">{{ item?.label   }}</label>  
                  </div>
                    
                    <div class="mt-2 flex items-center justify-between">
                      <span>
                        <a  *ngIf="item?.url" [href]="item?.url" target="_blank"  class="flex items-center" >
                          <span class="material-symbols-outlined mr-2"> link </span>
                          <span>{{  (langService.translations$ | async  )?.['modal-link-read-document'] ?? 'modal-link-read-document' }} </span>
                        </a>
                      </span>
                      <span *ngIf="item?.mandatory"  class=" rounded-full  text-center text-sm font-semibold px-2 py-0 w-fit bg-gray-400 text-gray-600">{{  (langService.translations$ | async  )?.['modal-badge-mandatory'] ?? 'modal-badge-mandatory' }}</span> 
                    </div>
                </div>


                <div class=" w-full h-14 flex items-center justify-between">                  
                    <div class="container">
                      <app-spinner  *ngIf="loader" [color]="'grey'"></app-spinner> 
                    </div> 
                    <button  type="button" (click)="submitData('manage-account-modal')"   > 
                      <span  class="ico cursor-pointer material-symbols-outlined">
                        save
                      </span>    
                      <span class=" uppercase font-semibold">{{  (langService.translations$ | async  )?.['toast-btn-submit'] ?? 'toast-btn-submit' }}</span> 
                    </button>
                  </div>
                
              </fieldset> 
              </div> 
                
            </div> 

        </div>
    </div>
  </div>  


  <!-- DOCUMENTS KIT modal -->
<div id="kit-documents-modal" tabindex="-1"   
  class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative w-[98%] md:w-[40%] max-h-full  max-w-4xl">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" (click)="closeModalKit()" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"  >
              <svg   class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Close modal</span>
          </button> 
          <div class="px-6 py-6 lg:px-8">
                  <h3 class="mb-4 text-xl font-semibold text-gray-900 dark:text-white flex items-center">
                      <span class=" material-symbols-outlined mr-2 text-4xl text-gray-500 dark:text-gray-400">picture_as_pdf</span>  
                      {{  ((langService.translations$ | async  )?.['modal-title-documents-list-'+ doctype] ?? 'modal-title-documents-list-'+ doctype) | uppercase }}
                  </h3>  
                  <div class=" max-h-170 overflow-auto flex items-center justify-center">                    
                      <!-- SPINNER -->
                      <div class="flex p-4 items-center justify-center h-auto w-full" *ngIf="( isLoadingDocumentsKitList$ | async )">
                        <app-spinner [color]="'#6575a6'"></app-spinner>
                      </div>  
                      
                      <div class="flex items-center justify-center gap-x-2 h-40 text-gray-900 dark:text-white" *ngIf="!( isLoadingDocumentsKitList$ | async ) && docsKitList.length < 1 ">
                        <span class="material-symbols-outlined text-4xl text-gray-500 dark:text-gray-400"> content_paste_off </span>
                        <span class="text-4xl text-gray-500 dark:text-gray-400 first-letter:capitalize">{{ (langService.translations$ | async  )?.['no-data-available'] ?? 'no-data-available'  }}</span></div>
                    
                      
                      <ul class="flex flex-col gap-y-2"   *ngIf="!( isLoadingDocumentsKitList$ | async )">                        
                          <li *ngFor="let doc of docsKitList" class="flex items"> 
                              <a [href]="doc.value"  target="_blank" class="docLinkButton"
                                  [appTooltip]="((langService.translations$ | async  )?.['tooltip-view-document'] ?? 'tooltip-view-document') | uppercase" placement="left"  
                              ><span class="ico pdf"></span>
                              <span class="text">{{ (langService.translations$ | async  )?.[doc.label] ?? doc.label }}</span></a> 
                          </li>
                      </ul>
                  </div> 
          </div> 
      </div>
  </div>
</div> 