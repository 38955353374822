import { Component, OnInit } from '@angular/core'; 
import { AppState, AuthService } from '@auth0/auth0-angular'; 
import { Store } from '@ngrx/store';
import { loadLoggedUser } from './store/app.actions';  
// import { DeviceDetectorService } from 'ngx-device-detector';
// import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './services/authentication.service';
// import { environment as env } from './../environments/environment'; 
// import { Loader } from '@googlemaps/js-api-loader';
// import { BROWSER_LANG_SHORT } from './app.module';
 
@Component({
  selector: 'app-root',   
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'] 
})
export class AppComponent implements OnInit {  
   

  constructor( 
    // private deviceService: DeviceDetectorService,
    // private cookieService: CookieService,
    private store: Store<AppState>, 
    private auth: AuthenticationService,
    public oAuth: AuthService ) {    
      // this.cookieService.delete('user-device');        
      // if(this.deviceService.isTablet()) {
      //   this.cookieService.set('user-device', 'TABLET', 365, '/'); 
      // } else if (this.deviceService.isMobile()) {     
      //   this.cookieService.set('user-device', 'MOBILE', 365, '/');  
      // } else if (this.deviceService.isDesktop()) {  
      //   this.cookieService.set('user-device', 'DESKTOP', 365, '/'); 
      // } 
    }

  ngOnInit(): void {  

    this.oAuth.isAuthenticated$.subscribe((isAuthenticated: boolean) => { 
      if (isAuthenticated) { 
        this.auth.getUserSettings(); // ora posso chiamarlo subito perché è svincolato dallo userId
        this.store.dispatch(loadLoggedUser()); // per salvare i dati dell'utente loggato in store applicativo
        this.getCoordinatesUser();

        // SET USER TIMEZONE ( for header parameter )
        const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; 
        sessionStorage.setItem('country', browserTimezone); 
      }
    });   
  }    


  //? GET USER LOCATION from browser API upon user acceptance
  getCoordinatesUser(): void { 
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        sessionStorage.setItem('userPosition', JSON.stringify(pos));  
        // this.setCountry(pos.lat, pos.lng);
         
      }, () => {
        sessionStorage.removeItem('userPosition'); 
        console.log('%c \n [INFO] :: %c La geolocalizzazione non è stata autorizzata. ', 'color: blue', 'color: cyan' ); 
      });
    } else {  
      console.log('%c [INFO] :: %c Il tuo browser non supporta la Geolocalizzazione.', 'color: blue', 'color: cyan' ); 
    }
  } 
  
   
   
}
