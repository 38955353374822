



<!-- <app-dashboard-component></app-dashboard-component>   -->




<div style="height: calc(100vh - 80px);" class=" flex items-center justify-center">
    <img src="./../../../assets/img/logo.png" alt="Logo" class="h-44 w-auto  border rounded-lg border-white">   
    
    <!-- <pre class="text-white">
        {{ (langService.translations$ | async  )?.['messaggio-subliminale-per-filippo']   }}
    </pre> -->
 

    <!-- TOOLTIP TESTs Flowbite tooltip  --> 
    <!-- Dark/light style tooltip -->
    <!-- 
    <button data-tooltip-target="tooltip-dark-light" 
        type="button" class="ms-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Dark/Light</button>

    <div id="tooltip-dark-light" 
        role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium rounded-lg shadow-sm opacity-0 tooltip
        text-gray-900 bg-gray-300 border border-gray-200 
        dark:text-gray-300 dark:border-black   dark:bg-black">
        Tooltip content
        <div class="tooltip-arrow bg-gray-300 dark:bg-black " data-popper-arrow></div>
    </div> --> 
 
</div> 

<!-- TEST SUPER COMBO ( don't delete ) -->
<!-- <app-combo-preview></app-combo-preview> -->

