/// <reference types="@angular/localize" />

import { enableProdMode } from "@angular/core";

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';



//********** SENTRY ********************************************************************
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (  environment.uat 
      || environment.staging
      || environment.production 
   ) { 

    Sentry.init({
      dsn: environment.sentry.dsn,  
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.browserProfilingIntegration(),
      ], 

      // Set tracesSampleRate to 1.0 to capture 100%  of transactions for PERFORMANCEs MONITORING.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5, //  10%  0.1 | 100% 1.0


      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled 
      tracePropagationTargets: [
        // 'localhost',
        /^https:\/\/uat\.crm\.acontel\.it\//,      // UAT
        /^https:\/\/crm\.acontel\.it\//            // PRODUCTION
      ],

      
      // Session Replay
      replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    
    console.log('%c [INFO] :: %c Application monitored by SENTRY', 'color: blue', 'color: cyan' ); 
}

//****************************************************************************************

if (environment.production) {
  enableProdMode(); // we gain performance but lose debug information
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.log('%c [INFO] :: %c Application UP and RUNNING in ENV : ' + environment.environment, 'color: blue', 'color: cyan' ))
  .catch(err => console.error(err));

 
