const serverBE = 'https://uat.api.acontel.it';  //?  BE UAT
// https://uat.crm.acontel.it (CRM UAT) 

export const environment = {

  environment: 'UAT',
  
  production: false, 
  staging: false,
  dev: false,        
  uat: true,        //! UAT ENV

  baseServerURL: `${serverBE}/`,    

  oAauthConfig: {
    domain: 'auth0.acontel.it', // ACONTEL
    client_id: '4KONhmqzQDMjXqxUsSdMzXvzS7xbGdsi', // ACONTEL
    audience: 'https://api.acontel.it', // ACONTEL
    redirect_uri: window.location.origin + '/dashboard',    
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    serverUrl: serverBE,   
    httpInterceptor: {
      allowedList: [
        `${serverBE}/api/*`,
        `${serverBE}/secure/*`
      ] 
    }
  },
  
  firebase: {
    projectId: 'acontel-app',
    appId: '1:287607134721:web:85c866c89b7c22986e3e00',
    databaseURL: 'https://acontel-app-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'acontel-app.appspot.com',
    apiKey: 'AIzaSyBQcsKi_azqXxt-VG_3u_cguL90EPBWbJk',
    authDomain: 'acontel-app.firebaseapp.com',
    messagingSenderId: '287607134721',
    measurementId: 'G-4K4F2YW3B8',
  },

  googleMapsApiKey: 'AIzaSyDdqcjBW9VxYEp m81ZLKL9eXrgl3LQZRj0',

  sentry: {
    dsn: 'https://138dab8cb7294cd3e6163b12032a4c9f@o4506734805385216.ingest.sentry.io/4506734808399872'
  }
};

