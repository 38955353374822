import { Directive,   ElementRef, Input, Renderer2, HostListener } from '@angular/core'; 
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Tooltip } from 'flowbite';
import type { TooltipOptions, TooltipInterface } from 'flowbite'; 
import { DeviceDetectorService } from 'ngx-device-detector';

@Directive({
  selector: '[appTooltip]', 
})
export class TooltipDirectiveFlowbiteWrapperDirective  {
  @Input('appTooltip') tooltipTitle = ''; 
  @Input() placement: any = 'top';
  tooltip: TooltipInterface | null = null;
  routerSubscription: Subscription; 

 
  constructor(
    private el: ElementRef<HTMLElement>, 
    private renderer: Renderer2, 
    private router: Router,
    private deviceService: DeviceDetectorService) {
    // Listen for route changes
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Hide tooltip when the route changes
        if (this.tooltip) { 
          this.tooltip.hide();
        }
      }
    });
  } 

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {  
      if(this.deviceService.isDesktop()) { 
        this.create(); 
      }
    } else {
      this.tooltip.show(); 
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) { 
      this.tooltip.hide(); 
    }
  } 

  create() {  

        const options: TooltipOptions = {
            placement: this.placement,
            triggerType: 'hover',
            onHide: () => {
                // console.log('tooltip is hidden');
            },
            onShow: () => {
                // console.log('tooltip is shown ');
            },
            onToggle: () => {
                // console.log('tooltip is toggled');
            },
        }; 
        
        const btn: HTMLElement = this.el.nativeElement;  // document.getElementById(this.btnId)!; 

        const conainerTooltip = this.renderer.createElement('div');       
          this.renderer.setAttribute(conainerTooltip, 'role', 'tooltip');

          this.renderer.addClass(conainerTooltip, 'tooltip');
          this.renderer.addClass(conainerTooltip, 'invisible');
          this.renderer.addClass(conainerTooltip, 'absolute'); 
          this.renderer.addClass(conainerTooltip, 'rounded-lg');
          this.renderer.addClass(conainerTooltip, 'px-3');
          this.renderer.addClass(conainerTooltip, 'py-2');
          this.renderer.addClass(conainerTooltip, 'text-md');
          this.renderer.addClass(conainerTooltip, 'font-medium'); 
          this.renderer.addClass(conainerTooltip, 'opacity-0');
          this.renderer.addClass(conainerTooltip, 'transition-opacity');
          this.renderer.addClass(conainerTooltip, 'duration-300'); 
        

        const tooltiparrow = this.renderer.createElement('div'); 
          this.renderer.addClass(tooltiparrow, 'tooltip-arrow'); 
          this.renderer.setAttribute(tooltiparrow, 'data-popper-arrow', '');
       

        this.renderer.appendChild(
          conainerTooltip,
          this.renderer.createText(this.tooltipTitle) // textNode
        );

        this.renderer.appendChild(
          conainerTooltip,
          tooltiparrow 
        );

        this.renderer.appendChild(document.body, conainerTooltip); 
         
        // CREATE a Flowbite Tooltip instance
        this.tooltip = new Tooltip(conainerTooltip, btn , options ) || null;

        // SHOW the Flowbite Tooltip instance
        this.tooltip.show(); 
  }
 
  
}
