import { createReducer , on} from '@ngrx/store';  
import { AppDataState } from '../interfaces/app-data-state'; 
import { loadAppState, loadAppStateFailure, loadAppStateSuccess, loadLoggedUser, loadLoggedUserFailure, loadLoggedUserSuccess, updatePlatform } from './app.actions';  
 

export const INITIALIZE_STATE: AppDataState = {    
  platform: {
    organizationId: 'org_Jfhsd78390js',
    platformId: 'pfm_000012', 
    name: 'Antonio Carraro', 
    db: 'AC',  
    logo: './../../../../assets/img/logo.png', 
    roleId: 'role_Fb0JBGV6TU', 
    website: '',
    companyStore: '',
    supportEmail: ''
  },
  loggedUser: null, 
  loading: false 
}; 
export const appReducer = createReducer(
  INITIALIZE_STATE, 

  on(loadAppState, state => ({...state, loading: true})),
  on(loadAppStateSuccess, (state, action) => ({...state, loading: false, action })),
  on(loadAppStateFailure, (state, {error}) => ({ ...state, loading: false, error })),

  on(loadLoggedUser, state => ({...state, loading: true})),
  on(loadLoggedUserSuccess, (state, action) => ({...state, loading: false, loggedUser: action.loggedUser })),
  on(loadLoggedUserFailure, (state, { error }) => ({ ...state, loading: false, error })),

  on(updatePlatform, (state, { update }) => ({ ...state, platform: { ...state.platform, ...update  }}))
 
);


//? un reducer è una PURE FUNCTION che prende una ACTION, lo STATE attuale e restituisce un NEW STATE aggiornato con i dati di ritorno della action ( se ce en sono ). 