
import { Injectable } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot,  Router } from '@angular/router';
import { Observable, map, take } from 'rxjs';
 
import { ToastService } from '../features/components/toast/toast.service';
import { AuthenticationService } from '../services/authentication.service';
import { LanguagesService } from '../services/languages/languages.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsGuard   {  

  activeToast: any = null; 

  constructor(private router: Router,   private authService: AuthenticationService, private langService: LanguagesService,  private toastService: ToastService ) {   }      
        
    canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  => {
    
      return new Observable<boolean>((observer) => { 
        this.authService.loggedUser$.pipe(
          take(2), //! DON'T REMOVE (memory leack problems on the corner) .... I had to pass this 2 times to make it work on F5 because first time user is not yet logged  
          map((user: any | null) => { 

            if (user ) {   //! questo controllo serve per non mandare in errore la guardia 
                          //! se l'utente non è ancora loggato perche' oAuth non ha ancora risposto       
                          
                let title =   'toast-title-missing-settings';
                let legend =   'toast-legend-required-settings';
                let errorMessage =   'toast-legend-required-settings';
                let btnlabel =  'toast-btn-submit';

                if(this.langService.translations$.value) { 
                  title = this.langService?.translations$?.value['toast-title-missing-settings'] ?? 'toast-title-missing-settings';
                  legend = this.langService?.translations$?.value['toast-legend-required-settings'] ?? 'toast-legend-required-settings';
                  errorMessage = this.langService?.translations$?.value['toast-error-message-fill-and-accept'] ?? 'toast-legend-required-settings';
                  btnlabel = this.langService?.translations$?.value['toast-btn-submit'] ?? 'toast-btn-submit';
                }  


               // console.log('>>> [USERSETTINGS GUARD]::: ', user); 
          
                const loadData = { 
                  btnlabel, 
                  error: errorMessage, 
                  legend, 
                  settings: [...user.metadata?.missingSettings || []] , 
                  loggedUserId: user.loggedUserId 
                };  
          
                if (user.metadata?.missingSettings.length === 0) {              
                  observer.next(true);
                  observer.complete();
                } else {  
                  if(!this.activeToast) {
                    // mostra un toast che richiede le impostanzioni mancanti
                    this.activeToast = this.toastService.showSingleToast(loadData, title , 'settings');
                  }
                
                  this.toastService.toastCompleted.subscribe(unlockGuard => {
                    if (unlockGuard) {
                      observer.next(true);
                      observer.complete();
                      this.activeToast = null;
                    }
                  });
                }

            } else { 
              // console.log('\n\n>>> [UserSettingsGuard] :: on F5 first time user is not yet logged in so I have to try 2 times \n\n'); 
            }
          })).subscribe();
      });
      
    } 
  
 
}
