import { Component } from '@angular/core'; 
import { CommonModule } from '@angular/common';     
// import { DashboardCompComponent } from '../vehicles/views/dashboard/dashboard.component';
 

@Component({
  selector: 'app-dashboard', 
  standalone: true,
  imports: [
    CommonModule, 
    // DashboardCompComponent
  ],  
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent   {  

   
   
}