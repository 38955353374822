import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core'; 
import { AuthenticationService } from '../services/authentication.service';
import { Subscription, filter, map  } from 'rxjs';

@Directive({
  selector: '[appHasRole]' 
})
export class HasRoleDirective implements OnDestroy{ 
  private _appHasRole: string[] = [];
  private subscription: Subscription = new Subscription();

  @Input()
  set appHasRole(value: string[]) {
    this._appHasRole = value;
    this.updateView(); // Chiama updateView ogni volta che il valore cambia
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {  
    // eventuale logica di inizializzazione
  }

  private updateView(): void {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Assicurati di disiscriverti dalla vecchia subscription
    }

    this.subscription = this.authService.userRole$.pipe(
      filter(role => role !== '???'),  //! deve corrispondere con il valore di default in AuthenticationService
      map((role: string) => { 
        // console.log('DIRECTIVE mio RUOLO >>>> : ', role);
        // console.log('DIRECTIVE incluso nel SET DI RUOLI AMMESSI ? >>>> : ', this._appHasRole);
        return  this._appHasRole.includes(role);
      }),
    ).subscribe(canRender => {
      if (canRender) {
        this.viewContainer.createEmbeddedView(this.templateRef); // Mostra l'elemento
      } else {
        this.viewContainer.clear(); // Rimuovi l'elemento
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Pulisci la subscription
    }
  }
}
