import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Toast } from './toast.interface'; 

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts: Toast[] = [];
  private toastsUpdated: BehaviorSubject<Toast[]> = new BehaviorSubject(this.toasts);
  private idCounter = 0; 

  public toastCompleted: Subject<boolean> = new Subject<boolean>();

  getToasts(): Observable<Toast[]> {
    return this.toastsUpdated.asObservable();
  }

  showToast(message: any, title: string, type: 'success' | 'error' | 'info' | 'settings' ): Toast { 
    const toast: Toast = { id: this.idCounter++, message, type, title };
    this.toasts = [toast];
    this.toastsUpdated.next(this.toasts);
    return toast;
  }

  showSingleToast(message: any, title: string, type: 'success' | 'error' | 'info' | 'settings' ): Toast { 
    const toast: Toast = { id: this.idCounter++, message, type, title };
    this.toasts = [toast];
    this.toastsUpdated.next(this.toasts);
    return toast;
  } 

  removeToast(id: number, unlockGuard = false): void {
    this.toasts = this.toasts.filter(toast => toast.id !== id);
    this.toastsUpdated.next(this.toasts);
    
    this.toastCompleted.next(unlockGuard); // serve per gestire logiche a valle della chiusura del toast da chiamante
  }

 
}
