import { createAction, props } from '@ngrx/store';  
import { OauthLoggedUserInterface } from '../interfaces/oauth-logged-user'; 
import { PlatformInterface } from '../interfaces/platform';

export const loadAppState = createAction('[APP] STATE');
export const loadAppStateSuccess = createAction( '[APP] Load APP STATE Success', props<{ data: any  }>() ); 
export const loadAppStateFailure = createAction( '[APP] Load APP STATE Failed', props<{ error: Error}>() );  

export const loadLoggedUser = createAction('[APP] Load Logged User' ); 
export const loadLoggedUserSuccess = createAction( '[APP] Load Logged User Success', props<{ loggedUser: OauthLoggedUserInterface }>() ); 
export const loadLoggedUserFailure = createAction( '[APP] Load Logged User failed', props<{error: Error}>() ); 

export const updatePlatform = createAction( '[APP] Update Platform Data', props<{ update: Partial<PlatformInterface> }>());


//? queste sono le ACTIONS che possono essere eseguite ( dispatched ) nella nostra applicazione.
//? ogni action è identificata da un nome univoco !!!! ('[APP] STATE') !!! e può avere un RES obj (PROPS) di dati associato.