import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard.component';

import { AuthGuard } from '@auth0/auth0-angular';
import { Page404Component } from './features/page404/page404.component'; 
import { UserSettingsGuard } from './guards/user-settings.guard';
import { RoleGuard } from './guards/role.guard';
import { AppVersionComponent } from './features/components/app-version/app-version.component';

const routes: Routes = [
  { path:'home', component: DashboardComponent , title: 'Acontel CRM : Home'},

  { path:'vehicles', 
    canActivate: [AuthGuard, UserSettingsGuard, RoleGuard], data: { expectedRoles: ['SUPERADMIN', 'AC_DEVELOPER', 'ADMIN', 'AC_DEALER', 'AC_RESELLER', 'AC_OWNER',  'AC_USER', 'AC_INSTALLER']},  
    loadChildren: () => import('./features/vehicles/vehicles.module').then(m => m.VehiclesModule)},
  { path:'users',    
    canActivate: [AuthGuard, UserSettingsGuard, RoleGuard], data: { expectedRoles: ['SUPERADMIN', 'AC_DEVELOPER', 'ADMIN', 'AC_DEALER', 'AC_RESELLER', 'AC_OWNER',  'AC_USER']},  
    loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule) },  

  { path:'controlpanel', 
    canActivate: [AuthGuard, UserSettingsGuard, RoleGuard], data: { expectedRoles: ['SUPERADMIN', 'AC_DEVELOPER', 'ADMIN', 'AC_DEALER', 'AC_RESELLER', 'AC_OWNER',  'AC_USER', 'AC_INSTALLER', 'AC_ACTIVATOR']},  
    loadChildren: () => import('./features/control-panel/control-panel.module').then(m => m.ControlPanelModule) },
  
  { path:'assistance', 
    canActivate: [AuthGuard],  
    title: 'Assistance',  loadComponent: () => import('./features/assistance/assistance.component').then(c => c.AssistanceComponent) },
  

  { path: 'statistics/enginefficiency', title: 'Efficiency Statistics',
      canActivate: [ RoleGuard ], data: { expectedRoles: ['SUPERADMIN', 'AC_DEVELOPER' ]}, // CONTROL ROUTES by ROLES
      loadComponent: () => import('./features/statistics/efficiency/efficiency.component').then(c => c.EfficiencyComponent)}, 

  { path: 'statistics/maintenance', title: 'Maintenance Statistics',
    canActivate: [ RoleGuard ], data: { expectedRoles: ['SUPERADMIN', 'AC_DEVELOPER' ]}, // CONTROL ROUTES by ROLES
    loadComponent: () => import('./features/statistics/maintenance/maintenance.component').then(c => c.MaintenanceStatisticsComponent)}, 
    

  // queste rotte devono stare esattamente qui e in quest'ordine
  { path:'dashboard', redirectTo: '/home', pathMatch: 'full'},
  { path: '',   redirectTo: '/home', pathMatch: 'full' }, 
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { } 