import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {trigger, state, style, animate, transition } from '@angular/animations'; 
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from './toast.service';
import { Toast } from './toast.interface';
import { AuthenticationService } from '../../../services/authentication.service'; 
import { AppState } from '@auth0/auth0-angular';
import { loadAppState, loadLoggedUser } from './../../../store/app.actions';
import { LanguagesService } from './../../../services/languages/languages.service';


@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ transform: 'translateX(-100%)', opacity: 0 })),
      state('*', style({ transform: 'translateX(0)', opacity: 1 })),
      transition('void <=> *', [
        animate('2000ms ease')
      ]),
    ])
  ] 
})
export class ToastComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();

  toasts: Toast[] = []; 

  showErrorsTaost = true;

  loader = false;
  showError  = false;
  formValues: any[] = [];

  response : any = null;

  constructor(public langService: LanguagesService ,private toastService: ToastService, private authService: AuthenticationService,   private store: Store<AppState>,) {}

    ngOnInit(): void {
      this.toastService.getToasts().pipe(takeUntil(this.unsubscribe$)).subscribe(toasts => {
        this.toasts = toasts; 
        
        //? only for settings special toast
        toasts[0]?.message?.settings?.forEach((field: any) => {
          this.formValues[field.name] = field.value;
        });

        setTimeout(() => this.showErrorsTaost = false, 15000);
      });
    } 

    closeToast(id: number): void {
      this.toastService.removeToast(id);
    }

    //? only for settings special toast
    submitData( toastId: number ): void { 
      this.showError = false;
      this.loader = true;
     
      const payload = { settings: {...this.formValues} };  

      this.authService.setUserSettings( payload).pipe(takeUntil(this.unsubscribe$)).subscribe({ 
        next: (response: any) => { 
           this.response = response; 
             if (response.data.missingSettings.length === 0) {  
                this.store.dispatch(loadLoggedUser());   // refresh in store user data and settings
                this.toastService.removeToast(toastId, true);   
             } else {
                this.showError = true;
             }  
        },
        error: (error: any) => { 
          console.error('>>> ERROR', error); 
          this.showError = true;
          this.response.data.error = error.message;
          this.loader = false;
          this.authService.getLoggedUser().pipe(takeUntil(this.unsubscribe$)).subscribe();  
        },
        complete: () => {
          this.authService.getLoggedUser().pipe(takeUntil(this.unsubscribe$)).subscribe();  
          this.loader = false;
        } 
        
      });  
    }


    ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
  }
  