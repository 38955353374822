import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'; 
import { LanguagesService } from './../../../../../../../services/languages/languages.service';
 
import {  Database, onValue, query, orderByChild, orderByKey, DataSnapshot, push, child, ref, update, remove } from '@angular/fire/database'; 
import { equalTo, get } from 'firebase/database';


 
@Injectable({
  providedIn: 'root'
})
export class ErrorsService { 

  translations$: BehaviorSubject<any> = new BehaviorSubject<any>(null);    

  errors$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);    

  availableLanguages: any[] = []; 

  constructor( 
    private database: Database,  
    private langService: LanguagesService ) { 
      const ln = this.langService.getCookieFavouriteLNG();  
      this.getFirebaseErrorsTerms(ln); 
    } 

    // TRANSLATONS
    getFirebaseErrorsTerms(ln: string ){  
      if(ln === '') return;  
      this.translations$.next( null );   
      const collection  = query(ref(this.database, `errors-translations/translations/${ln}`),  orderByKey() );
      onValue(collection, (snapshot: DataSnapshot) => {      
        const jsonRes =  snapshot.val();   // trasformo snapshot in un JSON
        this.translations$.next( jsonRes );  
        
        this.getFirebaselangs();
      }, (errorObject: any) => {
        console.error('>>>> retrieve fb translations ' , errorObject.name);
      }); 
    }

    // add a new error term for all languages
    addNewErrorTerm(definition: string, selector: string) {    
      selector = selector.toLowerCase(); 
      selector = selector.replace(/\./g, '-');   

      console.log('>>>>> addNewTerm Firebase: ', selector, definition);
      console.log('>>>>> this.availableLanguages: ', this.availableLanguages );

      for( const lan of this.availableLanguages) { 
        const nodeLang = ref(this.database, `errors-translations/translations/${lan.value}`);
        const data = { [String(selector)]: definition };

        console.log('>>>>> data: ', data );
  
        update(nodeLang, data).then(() => {
           console.log('>>>>> addNewTerm Firebase: success', lan.value); 
        }).catch((error) => {
          console.error('>>>>> addNewTerm Firebase: error', error);
        });  
      } 
    }
    
    getFirebaselangs() { 
      const collection  = query(ref(this.database, `errors-translations/languages`));
      onValue(collection, (snapshot: DataSnapshot) => {      
        const jsonRes =  snapshot.val();   // trasformo snapshot in un JSON         
        // const languageKeys = Object.keys(jsonRes);
        this.availableLanguages = jsonRes;
        //  console.log('>>> LANGs KEYs ', jsonRes);
      }, (errorObject: any) => {
        console.error('>>>> retrieve fb translations ' , errorObject.name);
      }); 
    }


    // ERRORS --------------------------------------------- 

    private parseDate(dateString: string): number {
        if (!dateString) return 0; 
       
        dateString = dateString.split('.')[0]; // Rimuovo i millisecondi, se presenti 
    
        // Uso Date.parse per gestire correttamente le date ISO in UTC
        const timestamp = Date.parse(dateString + "Z");  
     
        return timestamp;
    }
  

    async sortErrors(vehid: string): Promise<any[]> {
      const errorsRef = ref(this.database, `dtc/${vehid}/errors`);
  
      try {
        const snapshot: DataSnapshot = await get(errorsRef);
  
        if (!snapshot.exists()) {
          console.log(">>> Nessun errore trovato");
          return [];
        }
  
        const errors = snapshot.val() as Record<string, any>; 

        const allErrors = Object.entries(errors)
          .map(([key, value]) => ({
            id: key,
            ...value
          }))
          .sort((a, b) => {
            // Controllo che 'active' sia definito e sia booleano
            if (typeof a.active !== "boolean" || typeof b.active !== "boolean") {
              throw new Error("Il campo 'active' deve essere un booleano.");
            }

            // Prima ordino per il campo 'active'
            if (a.active !== b.active) {
              return a.active ? -1 : 1;
            }

            // Ordino per 'lastOccurrence' se 'active' è uguale
            const dateA = this.parseDate(a.lastOccurrence);
            const dateB = this.parseDate(b.lastOccurrence);

            // Aggiungo un controllo nel caso 'parseDate' restituisca NaN
            if (isNaN(dateA) || isNaN(dateB)) {
              throw new Error("Formato della data non valido in lastOccurrence");
            }

            // Ordino per data in ordine decrescente
            return dateB - dateA;
          });
   
        return allErrors;

      } catch (error) {
        console.error(">>> Errore nel recupero degli errori:", error);
        throw error;
      }
    }
  
    retrieveFirebaseErrors(vehid: string) {
      if (vehid === '') return;
  
      this.errors$.next([]);
  
      this.sortErrors(vehid)
        .then(errors => {
          // console.log(">>>>> Tutti gli errori ordinati:", errors);
          this.errors$.next(errors);
        })
        .catch(error => {
          console.error("Si è verificato un errore:", error);
        });
    }

    // WAS retrieveFirebaseErrors(vehid: string)  {
    //   if(vehid === '') return;  
    //   this.errors$.next( [] );   // resetto l'array

    //   const collection  = query(ref(this.database, `dtc/${vehid}/errors`), orderByChild('lastOccurrence') ); 
    //  // const collection = query(ref(this.database, `dtc/${vehid}/errors`), orderByChild('active'), equalTo(false));  // only active 


    //   onValue(collection, (snapshot: DataSnapshot) => {      
    //     // const jsonRes =  snapshot.val();   // trasformo snapshot in un JSON

    //     const values: any[] = [];
    //     snapshot.forEach((childSnapshot: any) => { 
    //       const errorId = childSnapshot.key; // PRENDO LA CHIAVE 
    //       const value = childSnapshot.val();
    //       values.push({ errorId, ...value }); // E LA AGGIUNGO AL DOCUMENTO  COME ID 
    //     });  
      
    //     //? SORT BY END DATE
    //     values.sort((a: any, b: any) => { 
    //       const aSerial = new Date(a.lastOccurrence).getTime();
    //       const bSerial = new Date(b.lastOccurrence).getTime();   
    //       // return aSerial - bSerial ; //ASC  
    //       return bSerial - aSerial; //DESC  
    //     });  


    //     //? SORT BY SEVERITY
    //     // const severityMap: { [key: string]: number } = {
    //     //   LOW: 1,
    //     //   MID: 2,
    //     //   HIGH: 3
    //     // };
       
    //     // values.sort((a: any, b: any) => {  
    //     //   const severityValueA = severityMap[a.severity] || 0;
    //     //   const severityValueB = severityMap[b.severity] || 0; 
    //     //   return severityValueB - severityValueA;  //DESC  

    //     //   // return b.severity.localeCompare(a.severity); // sort on string
    //     // });

    //     this.errors$.next(values);

    //   }, (errorObject: any) => {
    //     console.error('>>>> retrieve fb errors ' , errorObject.name);
    //   }); 
    // }
    

    async addNewError(vehid: string, error: any) {
      const newPostKey: string = push(child(ref(this.database), `dtc/${vehid}/errors`)).key as string;  
      const newError = ref(this.database, `dtc/${vehid}/errors/` + newPostKey); 
      return update(newError, error).then(() => {
                  console.log('Documento aggiunto con successo');
                  }).catch((error) => {
                    console.error('Si è verificato un errore durante l\'inserimento del documento:', error);
                  }); 
 
    }

    async deleteError(vehid: string, errorid: string) { 
      const errorPath = ref(this.database, `dtc/${vehid}/errors/` + errorid); 
      return remove(errorPath).then(() => {
                    console.log('Documento eliminato con successo');
                  }).catch((error) => {
                    console.error('Si è verificato un errore durante l\'eliminazione del documento:', errorid, error);
                  }); 
    }

    async updateError(vehid: string, errorid: string, error: any) {
      const errorPath = ref(this.database, `dtc/${vehid}/errors/` + errorid); 
      update(errorPath , error)
        .then(() => {
          console.log('>>> FIREBASE Update Error');
        })
        .catch((error) => {
          console.error('>>> FIREBASE Error updating data:', error);
        }); 
    }
}
