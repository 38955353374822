import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest, 
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LanguagesService } from '../services/languages/languages.service';
import { ToastService } from '../features/components/toast/toast.service';
import { AuthService } from '@auth0/auth0-angular'; 
import { environment as env } from '../../environments/environment';

@Injectable()
export class SecureInterceptor implements HttpInterceptor {

  activeToast: any = null;
  headerOriginPage  = '';

  constructor(
    private langService: LanguagesService,
    private toastService: ToastService,
    public oAuth: AuthService, 
    private router: Router) {}

  logout() {
    this.oAuth.logout({
      logoutParams: {
        returnTo: this.headerOriginPage // redirect di oAuth dopo il logout e deve essere la url di base dell'app
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.headerOriginPage = request.headers.get('Origin')! ;    

    // Customize header 
    const extendedReqHeader = request.clone({
      headers: request.headers
        .set('CAudience', env.oAauthConfig.audience) 
        .set('X-Timezone',   sessionStorage.getItem('country') || 'UTC')
        .set('X-Lang', this.langService.getCookieFavouriteLNG() || 'it')
    });

  

    return next.handle(extendedReqHeader).pipe(
      catchError((error: HttpErrorResponse) => {
        
        if (error.status === 403) {
          this.handle403code();
          return of();
        }

        if (error.status === 401) {
          this.handle401code();
          return of();
        } 

        const message =    (error.error.message ) ? error.error.message : error.message ;   
        this.toastService.showToast('SECURE (' + error.status + ') :::  ' + message,  'error', 'error');  

        // Rilancia l'errore per permettere ad altri eventuali interceptor di gestirlo o mostrare un messaggio all'utente 
        return throwError(() => new Error(error.message)); 
      })
    );
  }

  //! ..... HANDLED ERRORS  .............................

  handle403code() { 
    const title = this.langService.translations$.value['user-unauthorized-title'] ?? 'user-unauthorized-title' ;  
 
    const message = this.langService.translations$.value['user-unauthorized-instructions'] ?? 'user-unauthorized-instructions' ;  
    if(!this.activeToast) {
      this.activeToast = this.toastService.showToast( message,  title, 'info'); 
    } 

    this.toastService.toastCompleted.subscribe(unlockGuard => { 
        this.activeToast = null;  
        this.router.navigate(['/']);
    });
    
  }

  handle401code() {
    const title = this.langService.translations$.value['user-unknown-title'] ?? 'user-unknown-title' ;  
 
    const message = this.langService.translations$.value['user-unknown-instructions'] ?? 'user-unknown-instructions' ;  
    if(!this.activeToast) {
      this.activeToast = this.toastService.showToast( message,  title , 'info'); 
    } 

    this.toastService.toastCompleted.subscribe(unlockGuard => { 
        this.activeToast = null; 
        this.router.navigate(['/']);
        // this.logout();
    }); 
    
  }

}



 