import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment as env } from '../../environments/environment';   

@Injectable({
  providedIn: 'root'
})
export class DocsService {

  constructor(   private http: HttpClient) { }


      //* GET KIT DOCUMENTS TEMPLATES ************
      retrieveDocumentsKitList(doctype: string): Observable< any > {    
      
        const url = `${env.baseServerURL}api/generals/documentation?type=${doctype}`;   

        return this.http.get<any>(url, { 
          observe: 'response'
        }).pipe(
          map(response => {        
            if ( response.body && !response.body.success   ) { 
              throw { error: { message: response.body.message } }
            } else {  
              return  response.body ;  
            }     
          }) 
        );
    } 

}
